import * as Ariakit from "@ariakit/react";
import * as d3 from "d3"
import * as Tooltip from "@radix-ui/react-tooltip";
import { Button, Callout, Card, Flex, Heading, IconButton, Separator, Spinner, Strong, Text, Theme } from "@radix-ui/themes";
import { useCallback, useEffect, useRef, useState } from "react";
import { RiCloseLargeFill, RiErrorWarningLine, RiFileTextFill, RiFileTextLine, RiQuestionMark } from "react-icons/ri";
import { MapSidebar, MapStyles } from "../MapSidebar/MapSidebar";
import {
  SELECTED_COLOR,
  DEFAULT_BLUE,
  createContourLines,
  createLabel,
  createScatterPoints,
  destroyContourLines,
  destroyLabel,
  destroyScatterPoints,
  DEFAULT_POINT_COLOR,
  highlightContour,
} from ".";
import { createScatterPlot } from "./scatterPlot";
import type { BunkaTopicsResponse, Document, Topic } from "../TopicsContext/types";
import type { Tables } from "../../database.types";
import "./style.css";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { supabase } from "../../supabase";
import anime from 'animejs/lib/anime.es.js';

interface CreateMapResultType {
  g: d3.Selection<SVGGElement, unknown, null, undefined>;
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>;
}

interface Props {
  dataset: Tables<"datasets_with_user">;
}

const documentIcon = <svg id="icon" xmlns="http://www.w3.org/2000/svg" width="28px" height="28px" viewBox="0 0 300 300">
  <path d="M240.39,112.27l-79.77-79.77c-4.82-4.83-11.25-7.5-18.1-7.51h-55.77c-9.13,0-18.05,3.7-24.49,10.15-6.55,6.54-10.15,15.24-10.15,24.5v180.71c0,9.12,3.7,18.05,10.14,24.5,6.45,6.45,15.37,10.15,24.5,10.15h126.5c9.25,0,17.95-3.6,24.5-10.15,6.45-6.45,10.15-15.38,10.15-24.5v-109.98c0-6.74-2.73-13.34-7.5-18.1ZM222.15,115.36h-54.09c-2.78,0-5.49-1.12-7.45-3.09-1.96-1.96-3.08-4.67-3.08-7.44v-54.09l64.62,64.62ZM227.08,254.19c-3.7,3.7-8.61,5.73-13.84,5.73h-126.5c-5.15,0-10.19-2.09-13.83-5.73s-5.73-8.69-5.73-13.84V59.64c0-5.23,2.03-10.14,5.74-13.84,3.63-3.64,8.67-5.73,13.82-5.73h55.72v64.75c0,6.74,2.73,13.34,7.5,18.1,4.76,4.77,11.36,7.51,18.11,7.51h64.75v109.93c0,5.15-2.09,10.2-5.73,13.84ZM195.19,175.24c0,4.16-3.38,7.54-7.54,7.54h-75.29c-4.16,0-7.54-3.38-7.54-7.54s3.38-7.54,7.54-7.54h75.29c4.16,0,7.54,3.38,7.54,7.54ZM195.19,209.27c0,4.16-3.38,7.54-7.54,7.54h-75.29c-4.16,0-7.54-3.38-7.54-7.54s3.38-7.54,7.54-7.54h75.29c4.16,0,7.54,3.38,7.54,7.54Z" fill="#c3c2c8" />
</svg>

const chartIcon = <svg id="icon" xmlns="http://www.w3.org/2000/svg" width="28px" height="28px" viewBox="0 0 300 300">
  <path id="path-16" d="M125.2,89.47c4.42-1.81,6.53-6.86,4.72-11.27l-16.04-39.15c-1.81-4.41-6.87-6.53-11.27-4.72-4.42,1.81-6.53,6.86-4.72,11.27l16.04,39.15c1.37,3.34,4.6,5.36,8,5.36,1.09,0,2.2-.21,3.27-.65Z" fill="#c3c2c8" />
  <path id="path-15" d="M103.74,103.74c3.37-3.37,3.37-8.85,0-12.22l-29.91-29.91c-3.38-3.38-8.84-3.38-12.22,0-3.37,3.37-3.37,8.85,0,12.22l29.91,29.91c1.69,1.69,3.9,2.53,6.11,2.53s4.42-.84,6.11-2.53Z" fill="#c3c2c8" />
  <path id="path-14" d="M89.67,124.74c1.84-4.4-.23-9.46-4.63-11.31l-39.02-16.34c-4.4-1.84-9.47.24-11.31,4.63-1.84,4.4.23,9.46,4.63,11.31l39.02,16.34c1.09.46,2.22.67,3.33.67,3.38,0,6.59-1.99,7.98-5.3Z" fill="#c3c2c8" />
  <path id="path-13" d="M84.59,150c0-4.77-3.87-8.64-8.64-8.64h-42.31c-4.77,0-8.64,3.87-8.64,8.64s3.87,8.64,8.64,8.64h42.31c4.77,0,8.64-3.87,8.64-8.64Z" fill="#c3c2c8" />
  <path id="path-12" d="M45.61,202.11l39.15-16.04c4.42-1.81,6.53-6.86,4.72-11.27-1.8-4.42-6.85-6.52-11.27-4.72l-39.15,16.04c-4.42,1.81-6.53,6.85-4.72,11.28,1.37,3.34,4.59,5.37,8,5.37,1.09,0,2.2-.21,3.27-.65Z" fill="#c3c2c8" />
  <path id="path-11" d="M73.83,238.38l29.91-29.92c3.37-3.37,3.37-8.84,0-12.22-3.38-3.37-8.84-3.38-12.22,0l-29.91,29.92c-3.37,3.37-3.37,8.84,0,12.22,1.69,1.68,3.9,2.52,6.11,2.52s4.42-.84,6.11-2.53Z" fill="#c3c2c8" />
  <path id="path-10" d="M113.03,260.67l16.34-39.02c1.84-4.4-.23-9.46-4.63-11.31-4.41-1.86-9.46.23-11.31,4.63l-16.34,39.02c-1.84,4.4.23,9.46,4.63,11.31,1.09.46,2.22.68,3.33.68,3.38,0,6.59-2,7.98-5.31Z" fill="#c3c2c8" />
  <path id="path-9" d="M158.64,266.36v-42.31c0-4.78-3.87-8.65-8.64-8.65s-8.64,3.87-8.64,8.65v42.31c0,4.78,3.87,8.65,8.64,8.65s8.64-3.87,8.64-8.65Z" fill="#c3c2c8" />
  <path id="path-8" d="M197.39,265.67c4.42-1.81,6.53-6.85,4.72-11.28l-16.04-39.14c-1.81-4.41-6.87-6.56-11.27-4.72-4.42,1.81-6.53,6.85-4.72,11.28l16.04,39.14c1.37,3.34,4.6,5.37,8,5.37,1.09,0,2.2-.21,3.27-.65Z" fill="#c3c2c8" />
  <path id="path-7" d="M238.39,238.38c3.37-3.37,3.37-8.84,0-12.22l-29.91-29.91c-3.38-3.37-8.84-3.38-12.22,0-3.37,3.37-3.37,8.84,0,12.22l29.91,29.91c1.69,1.68,3.9,2.52,6.11,2.52s4.42-.84,6.11-2.53Z" fill="#c3c2c8" />
  <path id="path-6" d="M265.3,198.28c1.84-4.4-.23-9.46-4.63-11.31l-39.02-16.35c-4.41-1.85-9.46.24-11.31,4.63-1.84,4.4.23,9.47,4.63,11.31l39.02,16.34c1.09.46,2.22.68,3.33.68,3.38,0,6.59-2,7.98-5.31Z" fill="#c3c2c8" />
  <path id="path-5" d="M275,150c0-4.77-3.87-8.64-8.64-8.64h-42.3c-4.77,0-8.64,3.87-8.64,8.64s3.87,8.64,8.64,8.64h42.3c4.77,0,8.64-3.87,8.64-8.64Z" fill="#c3c2c8" />
  <path id="path-4" d="M221.8,129.92l39.15-16.04c4.41-1.81,6.52-6.86,4.72-11.27-1.8-4.42-6.85-6.52-11.27-4.72l-39.15,16.04c-4.41,1.81-6.52,6.86-4.72,11.27,1.37,3.35,4.59,5.37,8,5.37,1.09,0,2.2-.21,3.27-.65Z" fill="#c3c2c8" />
  <path id="path-3" d="M208.48,103.74l29.91-29.91c3.37-3.37,3.37-8.85,0-12.22s-8.84-3.38-12.22,0l-29.91,29.91c-3.37,3.37-3.37,8.85,0,12.22,1.69,1.69,3.9,2.53,6.11,2.53s4.42-.84,6.11-2.53Z" fill="#c3c2c8" />
  <path id="path-2" d="M186.57,85.03l16.34-39.02c1.84-4.4-.23-9.46-4.63-11.31-4.41-1.84-9.46.24-11.31,4.63l-16.34,39.02c-1.84,4.4.23,9.46,4.63,11.31,1.09.46,2.22.67,3.33.67,3.38,0,6.59-1.99,7.98-5.3Z" fill="#c3c2c8" />
  <path id="path-1" d="M150,84.58c-4.77,0-8.64-3.87-8.64-8.64v-42.31c0-4.77,3.87-8.64,8.64-8.64s8.64,3.87,8.64,8.64v42.31c0,4.77-3.87,8.64-8.64,8.64Z" fill="#c3c2c8" />
</svg>

export default function MapPlot({ dataset }: Props): JSX.Element {
  const { datasetID } = useParams();
  const [selectedTopic, setSelectedTopic] = useState<Topic>();
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  // TODO catch SVG plotting errors
  const [error, setError] = useState<string>();
  const [collapsed, setCollapsed] = useState(true);
  const [mapLoading, setMapLoading] = useState(true);
  const [mapVars, setMapVars] = useState<CreateMapResultType>();
  const [open, setOpen] = useState<boolean>(true)
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [apiData, setApiData] = useState<BunkaTopicsResponse>();
  const chartRef = useRef<SVGSVGElement | null>(null);
  const animation = useRef(null);

  useEffect(() => {
    if (selectedTopic) {
      highlightContour(selectedTopic.topic_id);
    }
  }, [selectedTopic]);

  useEffect(() => {
    animation.current = anime({
      targets: '.topic-card-container',
      opacity: [0, 1],
      duration: 500,
      easing: 'easeInQuad'
    });
  }, [dataset, selectedTopic])

  const parseData = useCallback(async (blob: Blob) => {
    try {
      const text = await blob.text(); // Convert Blob to string
      return JSON.parse(text); // Parse string as JSON
    } catch (exc) {
      setError("Error parsing topics processing result.");
      console.error(exc);
    }
  }, []);

  const createScatterMap = useCallback(
    (apiData: BunkaTopicsResponse) => createScatterPlot(apiData, svgRef, setSelectedDocument, setSelectedTopic, setCollapsed),
    [],
  );

  const [currentMapStyle, _setCurrentMapStyle] = useState<MapStyles>(MapStyles.createScatterMap);
  const createMapOptions: { [key: string]: (apiData: BunkaTopicsResponse) => CreateMapResultType } = {
    createScatterMap,
  };

  useEffect(() => {
    if (mapVars) {
      const { svg, g, xScale, yScale } = mapVars;
      destroyLabel(g);
      // TODO fix blue color from data-color attr
      g.selectAll("circle.document-centroid");
      if (!mapLoading && selectedDocument) {
        createLabel(selectedDocument, svg, g, xScale, yScale);
        d3.select(`.document-centroid[data-docid='${selectedDocument.doc_id.replace(/[^a-zA-Z0-9]/g, "")}']`);
      }
    }
  }, [selectedDocument]);

  /**
   * Filter data, setup the state data, launch SVG drawing
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const initScatterplot = useCallback((data: BunkaTopicsResponse) => {
    const DOCUMENTS_LIMIT = Infinity;
    let filteredData: BunkaTopicsResponse;
    if (data.topics.length) {
      const maxDocsPerTopics = DOCUMENTS_LIMIT / data.topics.length;
      let filteredTopics = data.topics
        .filter((topic) => topic.name.toLowerCase() !== "no-topic")
        .map((topic) => ({
          ...topic,
          top_doc_content: topic.top_doc_content
          // top_doc_content: topic.top_doc_content?.slice(0, maxDocsPerTopics),
        }));

      
      const filteredDocs = data.docs.filter((doc) => [...filteredTopics.map((topic) => topic.top_doc_content?.includes(doc.content))].includes(true));

      const uniqueTopicIds = [...new Set(filteredDocs.map(doc => doc.topic_id))];
      filteredTopics = filteredTopics.filter(topic => uniqueTopicIds.includes(topic.topic_id));

      filteredData = {
        topics: filteredTopics,
        terms: data.terms,
        docs: filteredDocs,
      };
    } else {
      const filteredTopics = data.topics;
      const filteredDocs = data.docs.slice(0, DOCUMENTS_LIMIT);
      filteredData = {
        topics: filteredTopics,
        terms: data.terms,
        docs: filteredDocs,
      };
    }

    setApiData(filteredData);
    // Call the function to create the SVG map
    if (filteredData && svgRef.current) {
      setMapVars(
        createMapOptions[currentMapStyle]({
          docs: data.docs,
          topics: filteredData.topics,
          terms: [],
        }),
      );
      setMapLoading(false);
    }
  }, []);



  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setMapLoading(true);
    if (!dataset.id) {
      return () => {
        setMapLoading(false);
      };
    }
    if (!dataset.process_topics_result_filepath || !dataset.process_topics_result_bucket) {
      setError("Topics processing result not exist.");
      setMapLoading(false);
      return;
    }
    // public map
    if (dataset.is_public) {
      axios.get(supabase.storage.from(dataset.process_topics_result_bucket).getPublicUrl(dataset.process_topics_result_filepath).data.publicUrl).then((res) => {
        const filteredTopics = res.data.topics.filter((x: { name: string }) => x.name !== "no-topic");
        const filteredData = { ...res.data, topics: filteredTopics };
        initScatterplot(filteredData);
      });
      return;
    }
    // private map
    supabase.storage
      .from(dataset.process_topics_result_bucket)
      .download(dataset.process_topics_result_filepath)
      .then(({ data, error }) => {
        if (error) {
          setError("Error downloading topics processing result.");
          setMapLoading(false);
          return;
        }
        parseData(data).then((jsonData) => initScatterplot(jsonData));
      });
    return () => {
      setApiData(undefined);
      setMapLoading(false);
    };
  }, []);


  useEffect(() => {
    if (chartRef.current) {
      const paths = chartRef.current.querySelectorAll("path");
      const n = Math.round(
        (selectedTopic?.size / apiData?.topics.reduce((acc, topic) => acc + topic.size, 0)) * 16
      );
      paths.forEach((path, index) => {
        if (index >= paths.length - n) {
          path.setAttribute("fill", "#416CD4");
        } else {
          path.setAttribute("fill", "#C3C2C7");
        }
      });
    }
  }, [chartRef, selectedTopic]);

  return (
    <div className="scatter-plot-and-text-container" style={{ position: "relative" }}>
      <div className="scatter-plot-container">
        {!!error && (
          <Callout.Root color="red" className="map-error">
            <Callout.Icon>
              <RiErrorWarningLine />
            </Callout.Icon>
            <Callout.Text>{error}</Callout.Text>
          </Callout.Root>
        )}
        <svg ref={svgRef} className="scatter-plot-svg" />
        {mapLoading && !error && <Spinner loading={true} className="scatter-plot-loader" />}
      </div>
      {selectedTopic && (
        <div className="topic-card-container">
          <div className='topic-card-section'>
            <div onClick={() => setSelectedTopic(undefined)} className="topic-card-close-button">
              <RiCloseLargeFill style={{ color: "#6B6D79" }} />
            </div>
            <div className="topic-card-title">
              {selectedTopic.title || selectedTopic?.name}
            </div>
            <div className="topic-statistics">
              <div className="top-bottom-container" style={{ color: "#326DDB" }}>
                <div className="topic-statistics-number" ref={chartRef}>
                  {chartIcon}
                  {Math.round(
                    (selectedTopic.size / apiData?.topics.reduce((acc, topic) => acc + topic.size, 0)) * 100
                  )}%
                </div>
                <div style={{ fontSize: 'small' }}>
                  of documents
                </div>
              </div>
              <div className="top-bottom-container">
                <div className="topic-statistics-number">
                  {documentIcon}
                  {selectedTopic?.size}
                </div>
                <div style={{ fontSize: 'small' }}>sampled documents</div>
              </div>
            </div>
          </div>
          {selectedTopic?.summary && (
            <>
              <div className='topic-card-divider'></div>
              <div className="topic-card-section">
                <div className="topic-description">
                  {selectedTopic?.summary}
                </div>
              </div>
            </>
          )}
          <div className="topic-card-divider"></div>
          <div className="topic-card-section">
            <div onClick={() => setSelectedTopic(apiData?.topics[(apiData?.topics.indexOf(selectedTopic) + 1) % apiData?.topics.length])} className="topic-next-button">
              Next topic
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

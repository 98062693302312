import { Badge, Button, Card } from "@radix-ui/themes";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Session, useSession } from "@supabase/auth-helpers-react";
import mapPreview from "/static/map-preview.png";
import type { Tables } from "../../database.types";
import "./style.css";
import { RiCloseLargeLine, RiCloseLine, RiCrossFill, RiCrosshairLine, RiCrossLine, RiDeleteBackFill, RiDeleteBin2Fill, RiDeleteBinFill, RiDeleteBinLine, RiXingLine } from "react-icons/ri";
import { postDeleteDataset } from "../TopicsContext/apiClient";
import { useState } from "react";

interface Props {
  dataset: Partial<Tables<"datasets_with_user">>;
  to?: string;
  showPreview: boolean;
  showTitle: boolean;
  session: Session | null;
  isSelected?: boolean;
}

export const DatasetCard = ({ dataset, to, showPreview, showTitle, session, isSelected = false }: Props): JSX.Element => {
  return (
    <div
      className="dataset-card"
      style={{
      }}
    >
      {to ? (
        <Link to={to} style={{ width: '100%' }}>
          <DatasetInside dataset={dataset} showPreview={showPreview} showTitle={showTitle} session={session} isSelected={isSelected} />
        </Link>
      ) : (
        <DatasetInside dataset={dataset} showPreview={showPreview} showTitle={showTitle} session={session} isSelected={isSelected} />
      )}
    </div>
  );
};

interface DatasetInsideProps {
  dataset: Partial<Tables<"datasets_with_user">>;
  showPreview: boolean;
  showTitle: boolean;
  session: Session | null;
  isSelected?: boolean;
}

const DatasetInside = ({ showPreview, showTitle, dataset, session, isSelected = false }: DatasetInsideProps): JSX.Element => {
  const location = useLocation();
  const [deletePopupIsOpened, setDeletePopupIsOpened] = useState(false);
  const isCreatePath = location.pathname.includes('/create');

  const handleDelete = async () => {
    if (!session) {
      console.log('Cannot delete dataset without session');
      return;
    }
    if (dataset.id != null) {
      console.log('Deleting dataset', dataset.id);
      await postDeleteDataset("topics", dataset.id, session);
      window.location.reload();
    } else {
      console.error('Dataset ID is missing');
    }
  }


  return (
    <>
      {/* {showPreview && <img className="map-preview" alt="Map" src={mapPreview} />} */}
      {deletePopupIsOpened && (
        <div className="delete-popup-overlay" onClick={(event) => { event.preventDefault(); event.stopPropagation(); setDeletePopupIsOpened(false); }}>
          <div className="delete-popup">
            <div className="delete-popup-text">
              <div className="header" style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'red' }}>Are you sure you want to delete this dataset?</div>
              <div className="name" style={{ marginTop: '1rem', fontSize: '1rem', fontWeight: 'normal', color: 'var(--collection-1-colors-neutral-dark-gray)' }}>{dataset.name}</div>
            </div>
            <div className="delete-popup-buttons">
              <Button color="gray" onClick={() => setDeletePopupIsOpened(false)} style={{ fontSize: '1.2rem', padding: '10px 20px', marginRight: '10px' }}>Cancel</Button>
              <Button color="red" onClick={handleDelete} style={{ fontSize: '1.2rem', padding: '10px 20px' }}>Delete</Button>
            </div>
          </div>
        </div>
      )}
      {showTitle ? (
        <div className={"hover-card" + (isSelected ? " selected" : "")}>
          {isCreatePath && (
            <div
              className="delete-icon"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeletePopupIsOpened(true)
              }}
            >
              <RiCloseLargeLine style={{ color: 'var(--collection-1-colors-neutral-dark-gray)' }} />
            </div>
          )}
          <div className="labels">
            <div className="header">
              <div className="label">{dataset.name}</div>
            </div>
            <div className="description">
              {dataset.description?.length > 0 ? dataset.description : "No description..."}
            </div>
            <div className="sub-header">
              {/* <div className="created">{dataset.created_at ? new Date(dataset.created_at).toLocaleDateString() : ""}</div> */}
              <div className="tags">

                {dataset?.is_public ? (
                  <></>
                  // <div color="blue">Public</div>
                ) : (
                  <div className="dataset-tag" style={{ color: '#104547', borderColor: '#104547' }}>Private</div>
                )}
                {/* {dataset?.tags?.rows && <Badge style={{ marginRight: '8px' }} color="blue">{dataset.tags.rows}</Badge>} */}
                {dataset?.tags?.language && <div className="dataset-tag" style={{ color: '#20A39E', borderColor: '#20A39E' }}>{dataset.tags.language === 'en' ? 'English' : dataset.tags.language}</div>}
                {dataset?.tags?.type && <div className="dataset-tag" style={{ color: '#FF595E', borderColor: '#FF595E' }}>{dataset.tags.type}</div>}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="labels">
          <div className="top">
            <div className="label">Bunka scatterplot of "{dataset?.selected_column}"</div>
          </div>
        </div>
      )}
    </>
  );
};

import { Card, Link, TabNav } from "@radix-ui/themes"
import './style.css'
import { Logo } from "../Logo"
import { AvatarIcon } from "./avatar"


export const Navbar = ({ session }: { session: boolean }) => {
    return (
        <div className="navbar-container">
            <div className="bunka-logo">
                <Link href="/">
                    <Logo />
                </Link>
            </div>
            <div className="nav-tabs" >
                <div 
                    className={(location.pathname.includes("/explore") || location.pathname == '/' ? "active-nav-button" : "nav-button")}
                    onClick={() => window.location.href = "/explore"}
                >
                    Explore
                </div>
                <div 
                    className={location.pathname.includes("/datasets") ? "active-nav-button" : "nav-button"}
                    onClick={() => window.location.href = "/datasets"}
                >
                    Datasets
                </div>
                {session && (
                    <div 
                        className={location.pathname === "/create" ? "active-nav-button" : "nav-button"}
                        onClick={() => window.location.href = "/create"}
                    >
                        Create
                    </div>
                )}
            </div>
            <div className="avatar-container">
                <div className="contact-us-button" onClick={() => {
                    window.open("https://www.bunka.ai/contact", "_blank");
                }}>
                    Contact us
                </div>
                <Link href="/user" className="avatar-icon">
                    <AvatarIcon />
                </Link>
            </div>
        </div >
    )
}
import { Auth } from "@supabase/auth-ui-react";
import { ThemeMinimal } from "@supabase/auth-ui-shared";
import { Logo } from "../../components/Logo";
import { supabase } from "../../supabase";
import "./style.css";

interface Props {
  passwordResetScreen: boolean;
  redirectTo?: string;
}

export const AuthSignIn = ({ passwordResetScreen, redirectTo = "/user" }: Props): JSX.Element => {
  return (
    <div className="auth-sign-in">
      <div className="frame-wrapper">
        <div className="frame">
          <div className="sign-in-title">
            Sign in
          </div>
          <Auth
            redirectTo={redirectTo}
            supabaseClient={supabase}
            showLinks={false}
            view={passwordResetScreen ? "update_password" : undefined}
            localization={{
              variables: {
                sign_in: {
                  email_label: "",
                  password_label: "",
                },
                sign_up: {
                  email_label: "",
                  password_label: "",
                },
              },
            }}
            magicLink={true}
            appearance={{
              theme: ThemeMinimal,
              style: {
                input: {
                  width: "240px",
                },
              },
              variables: {
                default: {
                  colors: {
                    brand: "var(--collection-1-colors-primary-p-100)",
                    brandAccent: "var(--collection-1-colors-primary-p-100)",
                    brandButtonText: "var(--collection-1-colors-neutral-white)",
                    inputBorder: "var(--collection-1-colors-neutral-light-gray)",
                    inputBorderFocus: "var(--collection-1-colors-neutral-dark-gray)",
                    inputBorderHover: "var(--collection-1-colors-neutral-dark-gray)",
                  },
                  radii: {
                    borderRadiusButton: "8px",
                    buttonBorderRadius: "8px",
                    inputBorderRadius: "4px",
                  },
                  fonts: {
                    bodyFontFamily: "Inter",
                    buttonFontFamily: "Inter",
                    inputFontFamily: "Inter",
                    labelFontFamily: "Inter",
                  },
                  space: { buttonPadding: "0.5rem 1rem", inputPadding: "0.5rem 1rem" },
                },
              },
            }}
            providers={[]}
          />
        </div>
      </div>
    </div>
  );
};

import * as d3 from 'd3';
import { configureZoom, createClusterCentroids, createContourLines, createLabel, createSVG, createScales, createScatterPoints, margin, plotHeight, plotWidth } from ".";
import type { BunkaTopicsResponse, Document, Topic } from "../TopicsContext/types";

const DOCUMENTS_LIMIT = 10_000;

export const createScatterPlot = (
  apiData: BunkaTopicsResponse,
  svgRef: React.MutableRefObject<SVGSVGElement | null>,
  setSelectedDocument: React.Dispatch<React.SetStateAction<Document | undefined>>,
  setSelectedTopic: React.Dispatch<React.SetStateAction<Topic | undefined>>,
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { docs: documents, topics } = apiData;


  const docs = documents.slice(0, DOCUMENTS_LIMIT);

  if (svgRef?.current) {
    const { svg, g } = createSVG(svgRef as React.MutableRefObject<SVGSVGElement>, plotWidth, plotHeight, margin);
    const zoom = configureZoom(svg, g);
    const { xScale, yScale } = createScales(docs, plotWidth, plotHeight);
    createContourLines(docs, g, xScale, yScale);
    createScatterPoints(docs, topics, g, svg, xScale, yScale, setSelectedTopic, setSelectedDocument, setCollapsed, zoom);
    createClusterCentroids(topics, g, xScale, yScale, setSelectedTopic, setSelectedDocument, setCollapsed);
    
    // Center the SVG and zoom out slightly
    const initialTransform = d3.zoomIdentity.translate(0,0).scale(1.01);
    svg.call(zoom.transform, initialTransform);
    const largestTopic = topics.reduce((max, topic) => topic.size > max.size ? topic : max, topics[0]);
    setSelectedTopic(largestTopic);
    return { svg, zoom, g, xScale, yScale };
  }
  console.error("SVG ref is null", svgRef);
  throw new Error("SVG element ref not defined");
};

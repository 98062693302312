import { Callout, Card } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import type { PostgrestSingleResponse, QueryError } from "@supabase/supabase-js";
import { useParams } from "react-router-dom";
import { MapDataCard } from "../../components/MapDataCard/MapDataCard";
import type { Tables } from "../../database.types";
import { supabase } from "../../supabase";
import "./style.css";
import MapPlot from "../../components/Maps/MapPlot";
import { DatasetCard } from "../../components/DatasetCard";
import { Button } from "@radix-ui/themes";
import { Footer } from "../../components/Footer";
import anime from 'animejs/lib/anime.es.js';


const datasetsQuery = (user_id?: string) => {
  const baseQuery = supabase
    .from("datasets_with_user_new")
    .select(`
    id,
    name,
    description,
    created_at,
    user_id,
    is_public,
    tags,
    username
`)
    .neq("process_topics_result_filepath", null)
    .order("created_at", { ascending: true });
  if (user_id) return baseQuery.eq("user_id", user_id);
  return baseQuery;
};

const datasetQuery = (id: string) => supabase.from("datasets_with_user_new").select("*").eq("id", id);

const formatDate = (dateString: string | undefined): string => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString(); // Adjust options as needed for formatting
};

export function ExploreView(): JSX.Element {
  const { id: urlDatasetID } = useParams<{ id?: string }>();
  const [datasetID, setDatasetID] = useState<string>(urlDatasetID || '457');
  const [datasets, setDatasets] = useState<Partial<Tables<"datasets_with_user">>[] | null>(null);
  const [datasetsLoading, setDatasetsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [dataset, setDataset] = useState<Tables<"datasets_with_user">>();
  const animation = useRef(null);

  useEffect(() => {
    if (animation.current) {
      animation.current.restart();
    }
  }, [dataset]);

  useEffect(() => {
    animation.current = anime({
      targets: '.details-container',
      opacity: [0, 1],
      duration: 500,
      easing: 'easeInQuad'
    });
    let loading = true;
    setDatasetsLoading(loading);
    const callback = ({ data, error }: PostgrestSingleResponse<Partial<Tables<"datasets_with_user">>[]>) => {
      if (loading && data !== null) {
        setDatasets(data);
      }
    };
    datasetsQuery()
      .eq("is_public", true)
      .then((args) => callback(args));
    setDatasetsLoading(false);

    return () => {
      loading = false;
      setDatasets(null);
      setDatasetsLoading(loading);
    };
  }, []);

  const filteredDatasets = datasets

  useEffect(() => {
    let loading = true;
    if (!datasetID) {
      return () => {
        loading = false;
      };
    }
    datasetQuery(datasetID).then(({ data, error }) => {
      // window.heap.track("view_dataset", {
      //   dataset_id: datasetID,
      //   dataset_name: dataset?.name,
      //   dataset_description: dataset?.description,
      //   dataset_created_at: dataset?.created_at,
      // });
      if (loading && data !== null) {
        const datasetResult = data[0];
        if (!datasetResult) {
          setError("Project does not exist.");
          loading = false;
          return;
        }
        setDataset(datasetResult);
      }
      if (error) {
        setError("Error fetching projet.");
      }
    });
    return () => {
      setDataset(undefined);
      loading = false;
    };
  }, [datasetID]);

  const handleSwitchDataset = (id: string) => {
    setDatasetID(id);
    const element = document.querySelector('.datacard-list');
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  return (
    <>
      <div className="explorer">
        {!!error && (
          <Callout.Root color="red" className="explorer-error">
            <Callout.Icon>
              <RiErrorWarningLine />
            </Callout.Icon>
            <Callout.Text>{error}</Callout.Text>
          </Callout.Root>
        )}
        <div className="map">
          {dataset && <MapPlot dataset={dataset} />}
        </div>
        <div className="sidebar">
          <div className="details">
            <div className="details-container">
              <h1 className="title">
                {dataset?.name}
              </h1>
              <p className="description">
                {dataset?.description ? dataset?.description :
                  "No description..."
                }
              </p>
              {/* <p className="date">
                Created on {formatDate(dataset?.created_at)}
              </p> */}
            </div>
            {/* <div className="tags">
            <p className="tag">{dataset?.model}</p>
            {dataset?.n_clusters && <p className="tag">{dataset?.n_clusters} Topics</p>}
          </div> */}
          </div>
          <div className="recommended-box">
            <h3>
              Explore others
            </h3>
            <div className="datacard-list">
              {filteredDatasets?.length ? (
                <>
                  {dataset && (
                    <div key={dataset.id} style={{ marginBottom: '1rem' }}>
                      <DatasetCard dataset={dataset} to={``} showPreview={true} showTitle={true} session={null} isSelected={String(dataset.id) == datasetID} />
                    </div>
                  )}
                  {filteredDatasets.filter((filteredDataset) => filteredDataset.id !== dataset?.id).map((dataset) => (
                    <div key={dataset.id} onClick={(e) => handleSwitchDataset(dataset.id)} style={{ marginBottom: '1rem' }}>
                      <DatasetCard dataset={dataset} to={``} showPreview={true} showTitle={true} session={null} isSelected={String(dataset.id) == datasetID} />
                    </div>
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

import { Spinner, TextField } from "@radix-ui/themes";
import { useSession } from "@supabase/auth-helpers-react";
import type { PostgrestSingleResponse, QueryError } from "@supabase/supabase-js";
import { useContext, useEffect, useState } from "react";
import { RiSearchLine, RiUploadFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { DatasetCard } from "../../components/DatasetCard";
import { EmptyCard } from "../../components/EmptyCard";
import { ImportModalWrapper } from "../../components/ImportModalWrapper";
import { TopicsContext } from "../../components/TopicsContext";
import type { TopicsProviderType } from "../../components/TopicsContext/types";
import type { Tables } from "../../database.types";
import { supabase } from "../../supabase";
import "./style.css";
import { ImportProgress } from "../../components/ImportProgress";
import { Footer } from "../../components/Footer";

const datasetsQuery = (user_id?: string) => {
  const baseQuery = supabase
    .from("datasets_with_user_new")
    .select(`
    id,
    name,
    description,
    created_at,
    user_id,
    is_public,
    tags,
    username
`)
    .neq("process_topics_result_filepath", null)
    .order("created_at", { ascending: true });
  if (user_id) return baseQuery.eq("user_id", user_id);
  return baseQuery;
};

interface Props {
  isPublic: boolean;
}

export const Projects = ({ isPublic }: Props): JSX.Element => {
  const [datasets, setDatasets] = useState<Partial<Tables<"datasets_with_user">>[] | null>(null);
  const [error, setError] = useState<QueryError | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const [datasetsLoading, setDatasetsLoading] = useState(false);
  const [importModalIsOpened, setImportModalIsOpened] = useState(false);
  const [fileData, setFileData] = useState<File>();
  const [searchQuery, setSearchQuery] = useState("");

  const { reloadKey } = useContext<TopicsProviderType>(TopicsContext);
  const navigate = useNavigate();
  // Supabase current user session
  const session = useSession();
  // Filter the datasets based on the search query
  const filteredDatasets = datasets?.filter((dataset) => dataset.name?.toLowerCase().includes(searchQuery.toLowerCase()));
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    let loading = true;
    setDatasetsLoading(loading);
    const callback = ({ data, error }: PostgrestSingleResponse<Partial<Tables<"datasets_with_user">>[]>) => {
      if (loading && data !== null) {
        setDatasets(data);
      }
      if (error) {
        setError(error);
      }
    };
    if (!isPublic) {
      datasetsQuery(session?.user.id).then((args) => callback(args));
    } else {
      datasetsQuery()
        .eq("is_public", true)
        .then((args) => callback(args));
    }
    setDatasetsLoading(false);

    return () => {
      loading = false;
      setDatasets(null);
      setDatasetsLoading(loading);
    };
  }, [isPublic, session, reloadKey]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    // Check if the mouse is actually leaving the dropzone
    const relatedTarget = event.relatedTarget as Node;
    if (!event.currentTarget.contains(relatedTarget)) {
      setDragActive(false);
    }
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!session) {
      navigate("/projects");
    }
    setDragActive(false);

    const file = event.dataTransfer.files[0];
    setFileData(file);
    setImportModalIsOpened(true);
  };

  const url_mappings = {
    'awesome-chatgpt-prompts': 419,
    'hackaprompt': 442,
    'data-is-better-together': 443,
    'stable-diffusion-prompts': 445,
    'semantic-similarity-scoring-task': 447,
    'generating-articles-from-headlines': 452,
    'openassistant-conversations-dataset-oasst': 457,
    'wildchat-french': 460,
    'stable-diffusion-prompts-gustavo-santana': 462,
    'rlhf-anthropic': 463,
    'airoboros-instruct-dataset': 465,
    'wildchat': 466
  }

  return (
    <>
      <div className="home-content">
        <div className="search-wrapper">
          <div className="search-field">
            <RiSearchLine />
            <input className='search-field-input' type="text" placeholder="Search the datasets…" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} >
            </input>
          </div>
        </div>

        <div className="dataset-list">
          <div className="datasets-items">
            <Spinner loading={datasetsLoading && !error} />

            {error && <p>Error : {error ? error.message : "nothing there"}</p>}
            {filteredDatasets?.length ? (
              <>
                {filteredDatasets.map((dataset) => (
                  // <div key={dataset.id} className="card-container">
                  //   <DatasetCard dataset={dataset} to={`/map/${dataset.id}/plot`} showPreview={true} showTitle={true} />
                  // </div>
                  <div key={dataset.id} className="card-container">
                    <DatasetCard 
                      dataset={dataset} 
                      to={`/datasets/${Object.keys(url_mappings).find(key => url_mappings[key] === dataset.id) || dataset.id}`} 
                      showPreview={true} 
                      showTitle={true} 
                      session={session} 
                    />
                  </div>
                ))}
                {session && location.pathname === "/create" && (
                  <EmptyCard
                    onClick={() => setImportModalIsOpened(true)}
                  />
                )}
              </>
            ) : null}
            {filteredDatasets && !filteredDatasets.length && (
              location.pathname === '/create' ?
                <EmptyCard
                  onClick={() => {
                    if (!session) {
                      navigate("/projects");
                    }
                    setImportModalIsOpened(true);
                  }}
                />
                :
                <></>
            )}
            {location.pathname.includes('datasets') && (
              <div style={{ color: 'var(--collection-1-colors-neutral-dark-gray)', width: '100%', textAlign: 'center' }}>
                Want to add a dataset? Reach out to us <a href="https://bunka.ai/contact" target="_blank" rel="noopener noreferrer">here</a>
              </div>
            )}
          </div>
          <ImportModalWrapper
            onOpenChange={setImportModalIsOpened}
            usageExceed={false}
            sizeExceed={false}
            isOpened={importModalIsOpened}
            initialImportFile={fileData}
            isPublic={isPublic}
          />
          {session && <ImportProgress user_id={session.user.id} />}
        </div>
      </div>
      <Footer />
    </>
  );
};

import { Flex, Spinner, TabNav } from "@radix-ui/themes";
import { useSessionContext } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { FallbackComponent } from "../../App";
import { Sidebar } from "../../components/Sidebar";
import { TopicsProvider } from "../../components/TopicsContext/TopicsContext";
import { AuthSignIn } from "../../screens/AuthSignIn";
import { supabase } from "../../supabase";
import "./index.css";
import { Navbar } from "../Navbar";

const datasetQuery = (id: string) => supabase.from("datasets").select("is_public").eq("id", id);

export const SessionWrapper = () => {
  const { pathname } = useLocation();
  const { datasetID } = useParams();
  const { isLoading, session, error } = useSessionContext();
  const [passwordResetScreen, showPasswordResetScreen] = useState<boolean>(false);
  const [mapLoading, setMapLoading] = useState(true);
  const [isPublicMap, setIsPublicMap] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState<'all' | 'essential' | null>(null);


  const atExplore = pathname.startsWith('/explore')

  if (session && cookiePreferences === 'all') {
    window.heap.identify(session.user.email);
    console.log("Welcome to Bunka Explore, ", session.user.email, '!');
  }

  useEffect(() => {
    if (cookiePreferences === 'all') {
      if (window.initHeapAnalytics) {
        window.initHeapAnalytics();
      }
    }
  }, [cookiePreferences, session]);

  useEffect(() => {
    let loading = true;

    // Cookie settings
    const storedPreferences = localStorage.getItem('cookiePreferences');
    console.log('Cookie preferences', storedPreferences);
    if (storedPreferences) {
      setCookiePreferences(storedPreferences as 'all' | 'essential');
    }

    if (pathname.startsWith("/map") && datasetID) {
      datasetQuery(datasetID).then((data) => {
        if (loading && !data?.error && data?.data.length) {
          const datasetResult = data.data[0];
          if (!datasetResult) {
            loading = false;
            return;
          }

          setIsPublicMap(datasetResult.is_public ?? false);
          setMapLoading(false);
          loading = false;
          return;
        }
      });
    } else {
      loading = false;
      setMapLoading(false);
      setIsPublicMap(false);
    }
    return () => {
      loading = false;
      setMapLoading(false);
      setIsPublicMap(false);
    };
  }, [pathname, datasetID]);

  supabase.auth.onAuthStateChange(async (event, _) => {
    if (event === "PASSWORD_RECOVERY") {
      // show screen to update user's password
      showPasswordResetScreen(true);
    }
    if (event === "USER_UPDATED") {
      // show screen to update user's password
      showPasswordResetScreen(false);
    }
  });


  function selectCookiePreferences(preference: 'all' | 'essential') {
    setCookiePreferences(preference);
    localStorage.setItem('cookiePreferences', preference);
  }

  return (
    <TopicsProvider>
      <div className="app-screen">
        <Navbar session={!!session} />
        <div className="app-content">
          {atExplore && <Outlet />}
          {!atExplore && (mapLoading || isLoading) && !error && <Spinner loading={true} />}
          {!atExplore && !mapLoading && (session || isPublicMap) && !isLoading && !error && <Outlet />}
          {!atExplore && !mapLoading && !isLoading && !isPublicMap &&
            (pathname === "/projects" || pathname === "/user") &&
            (!session || passwordResetScreen) && (
              <AuthSignIn passwordResetScreen={passwordResetScreen} redirectTo="/projects" />
            )}
          {!atExplore && !mapLoading && !isLoading && !isPublicMap &&
            (pathname !== "/projects" && pathname !== "/user") &&
            !session && (
              <Outlet />
            )}
          {!atExplore && error && <FallbackComponent errorText={error.message} />}
        </div>
        {
          cookiePreferences === null && (
            <div className="cookie-preferences">
              <div>
                <div style={{ fontSize: 'x-large', fontWeight: '600', textAlign: 'start' }}>
                  We value your privacy
                </div>
                <div style={{ fontSize: 'large', fontWeight: '400', textAlign: 'start', maxWidth: '920px' }}>
                  Our website uses cookies to provide essential functionality and to collect analytics data for measuring basic usage information. You can choose to accept these analytics cookies or reject them.
                </div>
                {/* <div style={{ fontSize: 'large', fontWeight: '400', textAlign: 'start', maxWidth: '920px', textDecoration: 'underline', paddingTop: '1rem' }}>
                  Essential cookies required for the website to function will always be active.</div> */}
              </div>
              <div>
              <div className="cookie-preferences-buttons">
                <div className="cookie-preferences-button" style={{ backgroundColor: 'green', color: 'white' }} onClick={() => selectCookiePreferences('all')}>Accept all cookies</div>
                <div className="cookie-preferences-button" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => selectCookiePreferences('essential')}>Reject non-essential</div>
              </div>
              <div style={{ fontSize: 'small', fontWeight: '400', textAlign: 'start', textDecoration: 'underline', paddingTop: '1rem' , cursor: 'pointer', width: 'fit-content'}} onClick={() => window.location.href = '/cookie-policy'}>
                For more details, please read our cookie policy
              </div>
                </div>
            </div>
          )
        }
      </div>
    </TopicsProvider>
  );
};

import * as d3 from "d3";
import * as d3Contour from "d3-contour";
import type { Document, Topic } from "../TopicsContext/types";
import { captureConsoleIntegration } from "@sentry/react";
import { zoom } from "d3";

interface Scales {
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

export const margin = {
  top: 20,
  right: 20,
  bottom: 50,
  left: 50,
};
export const plotWidth = window.innerWidth * 0.66;
export const plotHeight = window.innerHeight - 25;
export const DEFAULT_SIZE = 3;
export const DEFAULT_FONT_COLOR = 'rgba(60,60,60,1)'
export const DEFAULT_POINT_COLOR = 'rgba(60,60,60,1)'
// export const DEFAULT_BLUE = "rgba(94, 163, 252, 0.4)";
export const DEFAULT_BLUE = "rgba(14, 0, 217, .2)";
export const LIGHT_BLUE = "rgba(94, 163, 252, 0.2)";
// const SELECTED_COLOR = "rgba(255, 0, 0, 1)";

// export const SELECTED_COLOR = "rgba(106,118,12, 1)";
// export const SELECTED_COLOR = "rgba(123, 0, 32, 1)";
export const SELECTED_COLOR = "rgba(0, 0, 0, 1)";




/**
 * Main SVG Initialization function
 * @param svgRef
 * @param width
 * @param height
 * @param margin
 * @returns
 */
export function createSVG(
  svgRef: React.MutableRefObject<SVGSVGElement>,
  width: number,
  height: number,
  margin: { top: number; right: number; bottom: number; left: number },
) {
  d3.select(svgRef.current).selectAll("*").remove();
  const svg = d3.select<SVGSVGElement, unknown>(svgRef.current).attr("viewBox", [0, 0, width, height]).attr("width", width).attr("height", height);

  const g = svg.append("g").classed("canvas", true).attr("transform", `translate(${margin.left}, ${margin.top})`);



  return { svg, g };
}

/**
 * Configure Zooming and auto-scaling sizes
 * @param svg
 * @param g
 */
export function configureZoom(
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  g: d3.Selection<SVGGElement, unknown, null, undefined>
) {
  const zoom = d3
    .zoom<SVGSVGElement, unknown>()
    .scaleExtent([0.9, 20])
    .on("zoom", ({ transform }) => {
      g.attr("transform", transform);

      g.selectAll(".label-group")
      .each(function () {
        const label = d3.select(this);
        const originalTransform = label.attr("transform");
        
        if (originalTransform) {
          // Extract just the translate portion from the original transform
          const translateMatch = originalTransform.match(/translate\(([^)]+)\)/);
          if (translateMatch) {
            // Apply translation with fresh scale
            const newTransform = `${translateMatch[0]} scale(${1 / transform.k})`;
            label.attr("transform", newTransform);
          }
        }
      });


      // Adjust contour opacity based on zoom level
      g.selectAll("path.contour")
        .style("opacity", Math.min(1 / (transform.k * 4), 0.4)); // Decrease opacity faster as zoom level increases
      const scalingFactor = 2.5;
      const sizeMultiplier = 5;
      const minSize = .5; // Define a minimum size

      g.selectAll("rect.document-centroid")
        .attr("width", () => {
          const newSize = Math.max(Math.round((scalingFactor / transform.k) * sizeMultiplier) / sizeMultiplier, minSize);
          return `${newSize}px`;
        })
        .attr("height", () => {
          const newSize = Math.max(Math.round((scalingFactor / transform.k) * sizeMultiplier) / sizeMultiplier, minSize);
          return `${newSize}px`;
        });

      // Exclude the label group from scaling
      const scalingSpeed = 20;
      const minFontSize = 2;
      const maxFontSize = 16;
      const minStrokeWidth = 0.01;
      const maxStrokeWidth = 0.02;


      function transformRound(k: number) {
        return String(Math.round(k * 10) / 10);
      }
      // Check if the zoom level has changed
      if (transformRound(transform.k) !== g.attr("data-last-zoom")) {
        g.attr("data-last-zoom", transformRound(transform.k)); // Store the current zoom level rounded by a factor of .2
        // if (transform.k < 1.5) {
        //   updateLabelConfig({
        //     fontSize: 12,
        //     targetWidth: 300,
        //     padding: 12,
        //     rx: 5,
        //     ry: 5,
        //     triangleScale: 1,
        //     pointRadius: 7,
        //     strokeWidth: 2,
        //   });
        // } else if (transform.k >= 1.5 && transform.k < 3) {
        //   updateLabelConfig({
        //     fontSize: 8,
        //     targetWidth: 200,
        //     padding: 8,
        //     maxWords: 50,
        //     rx: 3.33,
        //     ry: 3.33,
        //     triangleScale: 0.67,
        //     pointRadius: 4.7,
        //     strokeWidth: 1.33,
        //   });
        // } else if (transform.k >= 3 && transform.k < 4.5) {
        //   updateLabelConfig({
        //     fontSize: 4,
        //     targetWidth: 100,
        //     padding: 4,
        //     maxWords: 50,
        //     rx: 1.67,
        //     ry: 1.67,
        //     triangleScale: 0.33,
        //     pointRadius: 2.3,
        //     strokeWidth: 0.67,
        //   });
        // } else if (transform.k >= 4.5 && transform.k < 7) {
        //   updateLabelConfig({
        //     fontSize: 2.5,
        //     targetWidth: 75,
        //     padding: 2.5,
        //     maxWords: 50,
        //     rx: 1.25,
        //     ry: 1.25,
        //     triangleScale: 0.25,
        //     pointRadius: 1.4,
        //     strokeWidth: 0.33,
        //   });
        // } else if (transform.k >= 7) {
        //   updateLabelConfig({
        //     fontSize: 1.33,
        //     targetWidth: 40,
        //     padding: 1.33,
        //     maxWords: 50,
        //     rx: .67,
        //     ry: .67,
        //     triangleScale: 0.13,
        //     pointRadius: .75,
        //     strokeWidth: 0.18,
        //   });
        // }

        g.selectAll("text[class='topic-label']")
          .attr("fill", DEFAULT_FONT_COLOR)
          .style('font-family', '"Montserrat", sans-serif')
          .style('font-weight', 'bold')
          .attr("stroke", "white")
          .attr("stroke-width", 0.5)
          .style("font-size", `${Math.max(Math.round(Math.min(scalingSpeed / transform.k, maxFontSize)), minFontSize)}px`)
          .attr("stroke", "white")
          .attr("stroke-width", `${Math.round(Math.min((scalingSpeed / transform.k) * maxStrokeWidth, minStrokeWidth) * 100) / 100}`);

        const textLabels = g.selectAll("text[class='topic-label']")

        g.selectAll("rect.text-bbox").remove();
        let rectangles = [];
        textLabels.each(function (d) {
          const textBBox = this.getBBox(); // Get bounding box of the text
          const padding = 4; // Define padding value
          rectangles.push([
            textBBox.x - padding,
            textBBox.y - padding,
            textBBox.width + 2 * padding,
            textBBox.height + 2 * padding,
            d.size,
            d.topic_id
          ]);
        });

        // Sort rectangles by importance (descending)
        rectangles.sort((a, b) => b[4] - a[4]);
        // const rectangleVisibility = getVisibleRectangles(rectangles);

        const rectangleVisibility = maxNonOverlappingRectangles(rectangles);

        g.selectAll("text[class='topic-label']")
          .filter(function (d, i) {
            const topic_id = d.topic_id;
            return rectangleVisibility[topic_id];
          })
          .transition()
          .duration(500)
          .style("opacity", 1)

        g.selectAll("text[class='topic-label']")
          .filter(function (d) {
            const topic_id = d.topic_id;
            return !rectangleVisibility[topic_id];
          })
          .style("opacity", 0);
      }

    })


  svg.call(zoom);
  return zoom;
}

function maxNonOverlappingRectangles(rectangles) {

  // Helper function to check if two rectangles overlap
  function overlap(r1, r2) {
    /*
      r1 and r2 are of the form:
        [x, y, height, width, importance, id]
      We'll extract x, y, height, width and compute bounding boxes:
        (x, y) -> (x + width, y + height)
    */
    const [x1, y1, w1, h1] = r1;
    const [x2, y2, w2, h2] = r2;

    // Return true if there's any overlap (intersection) in both x and y dimensions
    // Overlap is false if either is completely to the left, right, above, or below the other
    if (x1 + w1 <= x2 || x2 + w2 <= x1) return false;  // no overlap in x
    if (y1 + h1 <= y2 || y2 + h2 <= y1) return false;  // no overlap in y

    return true;
  }

  // 1. Sort rectangles by importance (descending)
  const sortedRects = rectangles.slice().sort((a, b) => b[4] - a[4]);

  // 2. Greedily pick rectangles
  const chosen = [];
  const shownMap = {};
  rectangles.forEach(rect => {
    shownMap[rect[5]] = false; // Initialize all as not shown
  });

  for (let rect of sortedRects) {
    let hasOverlap = false;
    for (let c of chosen) {
      if (overlap(rect, c)) {
        hasOverlap = true;
        break;
      }
    }
    if (!hasOverlap) {
      chosen.push(rect);
    }
  }

  // 3. Mark chosen rectangles as true in shownMap
  chosen.forEach(c => {
    const id = c[5]; // the last element is the ID
    shownMap[id] = true;
  });

  return shownMap;
}

/**
 * DEPRECATED
 *
 * Bunkatopic's Document.size is not used anymore
 */
export function normalizeSizes(items: Document[]): number[] {
  const sizes = items.map((item) => item.size ?? DEFAULT_SIZE).filter((size) => size !== undefined) as number[];
  const minSize = Math.min(...sizes);
  const maxSize = Math.max(...sizes);
  if (maxSize === minSize) return items.map((_item) => DEFAULT_SIZE);

  return sizes.map((size) => {
    if (size === undefined) return 0; // Cas pour les valeurs undefined
    return (size - minSize) / (maxSize - minSize);
  });
}

/**
 *
 * Génère une couleur bleue avec une transparence basée sur une valeur normalisée
 */
export function getBlueColor(normalizedValue: number): string {
  if (normalizedValue === DEFAULT_SIZE) return DEFAULT_BLUE;
  const alpha = Math.round(normalizedValue * 255);
  return `rgba(94, 163, 252, ${alpha / 255})`;
}

/**
 * Initialize D3 scales from bunkatopics data
 * @param documents
 * @param plotWidth
 * @param plotHeight
 * @returns
 */
export function createScales(documents: { x: number; y: number }[], plotWidth: number, plotHeight: number): Scales {
  const xMin = d3.min(documents, (d) => d.x) || 0;
  const xMax = d3.max(documents, (d) => d.x) || 0;
  const yMin = d3.min(documents, (d) => d.y) || 0;
  const yMax = d3.max(documents, (d) => d.y) || 0;
  const xScale = d3.scaleLinear().domain([xMin, xMax]).range([0, plotWidth]);
  const yScale = d3.scaleLinear().domain([yMin, yMax]).range([plotHeight, 0]);
  return { xScale, yScale, xMin, xMax, yMin, yMax };
}

/**
 * Toggle selected highlight
 * @param elt
 * @param currentlyClickedPolygon
 * @param clickedRgba
 * @param styleProperty
 * @returns
 */
export function highlightSelectedElement(
  elt: SVGElement,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  currentlyClickedPolygon: d3.Selection<any, unknown, null, undefined> | null,
  // clickedRgba = "rgba(200, 200, 200, 0.4)",
  clickedRgba = "rgba(200, 200, 200, 0.4)",
  styleProperty = "fill",
) {
  // Reset the fill color of the previously clicked polygon to transparent light grey
  if (currentlyClickedPolygon) {
    currentlyClickedPolygon.style(styleProperty, currentlyClickedPolygon.attr("data-color"));
  }

  // Set the fill color of the clicked polygon to transparent light grey and add a red border
  const clickedPolygon = d3.select(elt);
  clickedPolygon.style(styleProperty, clickedRgba);
  return clickedPolygon;
}

/**
 * Create contours
 * @param topics
 * @param g
 * @param xScale
 * @param yScale
 * @returns
 */
export function createConvexHullContours(
  topics: Topic[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
) {
  const convexHullData = topics.filter((d) => d.convex_hull);
  const paths = [];
  for (const d of convexHullData) {
    const hull = d.convex_hull;
    const hullPoints: Array<[number, number]> = hull.x_coordinates.map((x, i) => [xScale(x), yScale(hull.y_coordinates[i])]);

    paths.push(
      g
        .append("path")
        .datum(d3.polygonHull(hullPoints))
        .attr("class", "convex-hull-contour")
        .attr("id", d.topic_id)
        .attr("d", (d1) => `M${d1?.join("L")}Z`)
        .style("fill", "none")
        .style("stroke", "transparent")
        .style("data-color", "transparent")
        .style("stroke-width", 2),
    );
  }
  return paths;
}

/**
 * DEPRECATED ConvexHull surfaces
 * @param topics
 * @param g
 * @param xScale
 * @param yScale
 * @returns
 */
export function createConvexHullPolygons(
  topics: Topic[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
) {
  const centroids = topics.filter((d) => d.x_centroid && d.y_centroid);
  // Add polygons for topics. Delete if no clicking on polygons
  const topicsPolygons = g
    .selectAll("polygon.topic-polygon")
    .data(centroids)
    .enter()
    .append("polygon")
    .attr("id", (d) => d.topic_id)
    .attr("class", "topic-polygon")
    .attr("points", (d) => {
      const hull = d.convex_hull;
      const hullPoints = hull.x_coordinates.map((x, i) => [xScale(x), yScale(hull.y_coordinates[i])]);
      return hullPoints.map((point) => point.join(",")).join(" ");
    })
    .attr("data-color", "transparent")
    .style("fill", "transparent");

  return topicsPolygons;
}

/**
 * Topographic map background
 * @param documents
 * @param g
 * @param xScale
 * @param yScale
 */

export function createContourLines(
  documents: Document[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
) {
  // Add contours
  const contourData = d3Contour
    .contourDensity()
    .x((d: [x: number, y: number]) => xScale(d[0]))
    .y((d: [x: number, y: number]) => yScale(d[1]))
    .size([plotWidth, plotHeight])
    .bandwidth(22)(documents.map((d) => [d.x, d.y]));

  // Define a custom color for the contour lines
  const contourLineColor = "rgb(94, 163, 252, 0.1)";
  const colorScale = d3.scaleSequential(d3.interpolateBlues)
    .domain([0, d3.max(contourData, d => d.value) || 1])// Adjust domain based on contour values

  // Append the contour path to the SVG with a custom color
  g.selectAll("path.contour")
    .data(contourData)
    .enter()
    .append("path")
    .attr("class", "contour")
    .attr("d", d3.geoPath())
    .style("fill", d => colorScale(d.value * .8)) // Fill with color based on density
    // .style("fill", "none")
    .style("stroke", contourLineColor) // Set the contour line color to the custom color
    .style("stroke-width", 1);
}

/**
 * Remove topographic map background
 * @param g SVGElement
 */
export function destroyContourLines(g: d3.Selection<SVGGElement, unknown, null, undefined>) {
  g.selectAll("path[class='contour']").remove();
}

// export let labelConfig = {
//   targetWidth: 300,
//   padding: 12,
//   fontSize: 12,
//   maxWords: 50,
//   rx: 5,
//   ry: 5,
//   triangleScale: 1,
//   pointRadius: 5,
//   strokeWidth: 1,
// };
export let labelConfig = {
  targetWidth: 400,
  padding: 16,
  fontSize: 16,
  maxWords: 50,
  rx: 6.67,
  ry: 6.67,
  triangleScale: 1.33,
  pointRadius: 6.67,
  strokeWidth: 1.33,
};

// Function to update label configuration
export function updateLabelConfig(newConfig: Partial<typeof labelConfig>) {
  labelConfig = { ...labelConfig, ...newConfig };
}

export function createLabel(
  document: Document,
  topicColorMap: Record<string, string>,
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>
) {
  const { fontSize, targetWidth, padding, maxWords, rx, ry, triangleScale, pointRadius, strokeWidth } = labelConfig;
  // Truncate the document content to the first 50 words
  const words = document.content.split(/\s+/);
  const displayedWords = words.slice(0, maxWords);
  const text = displayedWords.join(" ") + (words.length > maxWords ? "..." : "");

  // Enhanced wrap text function that considers natural breaks
  const wrapText = (text: string) => {
    const words = text.match(/\S+\s*[.,;!?]|\S+/g) || [];
    const lines: string[] = [];
    let currentLine = '';

    const tempText = g.append("text")
      .attr("class", "temp-text")
      .style("font-size", `${fontSize}px`)
      .style("visibility", "hidden");

    words.forEach(word => {
      tempText.text(currentLine + ' ' + word);
      const width = tempText.node()!.getComputedTextLength();

      if (width > targetWidth && currentLine !== '') {
        lines.push(currentLine.trim());
        currentLine = word;
      } else {
        currentLine += (currentLine ? ' ' : '') + word;
      }

      if (/[.!?]$/.test(word)) {
        lines.push(currentLine.trim());
        currentLine = '';
      }
    });

    if (currentLine) {
      lines.push(currentLine.trim());
    }

    tempText.remove();
    return lines;
  };

  const wrappedText = wrapText(text);
  const lineHeight = fontSize * 1.2;

  // Calculate the maximum width of all lines
  const tempText = g.append("text")
    .attr("class", "temp-text")
    .style("font-size", `${fontSize}px`)
    .style("visibility", "hidden");

  const maxWidth = Math.max(...wrappedText.map(line => {
    tempText.text(line);
    return tempText.node()!.getComputedTextLength();
  }));

  tempText.remove();

  const boxWidth = maxWidth + padding * 2;
  const boxHeightWithPadding = wrappedText.length * lineHeight + padding * 2;
  const triangleHeight = 8 * triangleScale;
  
  // Calculate the anchor point (document coordinates)
  const anchorX = xScale(document.x);
  const anchorY = yScale(document.y);
  
  // Position the group at the anchor point
  const labelGroup = g.append("g")
    .attr("class", "label-group")
    .attr('opacity', 0)
    .attr("transform", `translate(${anchorX}, ${anchorY})`);

  // Create a nested group for the label content, positioned relative to anchor
  const contentGroup = labelGroup.append("g")
    .attr("transform", `translate(${-boxWidth/2}, ${-(boxHeightWithPadding + triangleHeight + pointRadius + 1)})`);

  // Add rectangle to content group (now positioned relative to 0,0)
  contentGroup.append("rect")
    .attr("width", boxWidth)
    .attr("height", boxHeightWithPadding)
    .attr("rx", rx)
    .attr("ry", ry)
    .attr("fill", "white");

  // Triangle dimensions
  const triangleWidth = 14 * triangleScale;
  const overlapOffset = 2 * triangleScale;

  // Triangle is now positioned relative to the content group
  const trianglePath = [
    "M", boxWidth / 2 - triangleWidth / 2, boxHeightWithPadding - overlapOffset,
    "L", boxWidth / 2, boxHeightWithPadding + triangleHeight - overlapOffset,
    "L", boxWidth / 2 + triangleWidth / 2, boxHeightWithPadding - overlapOffset,
    "Z"
  ].join(" ");

  contentGroup.append("path")
    .attr("d", trianglePath)
    .attr("fill", "white");

  // Add text to content group
  wrappedText.forEach((line, i) => {
    contentGroup.append("text")
      .attr("x", padding)
      .attr("y", padding + fontSize * 0.8 + (i * lineHeight))
      .attr("class", "node-label")
      .style("fill", "black")
      .style("text-anchor", "left")
      .style("font-size", `${fontSize}px`)
      .text(line);
  });

  labelGroup.transition()
    .duration(300)
    .attr('opacity', 1);

  // Circle is now centered at 0,0 relative to the label group
  labelGroup.append("circle")
    .attr('class', 'label-circle')
    .attr("transform", `translate(0, 0)`)
    .attr('z-index', 1000)
    .attr("fill", topicColorMap[document.topic_id])
    .attr('opacity', 1)
    .attr("r", 0)
    .transition()
    .duration(800)
    .attr("r", pointRadius)
    .attr('stroke', 'rgba(255, 255, 255, 1)')
    .attr('stroke-width', strokeWidth);

  const currentTransform = d3.zoomTransform(svg.node() as Element);
  svg.call(zoom.scaleTo, currentTransform.k); // Apply the current zoom level without changing the zoom
}

export function destroyLabel(g: d3.Selection<SVGGElement, unknown, null, undefined>) {
  g.selectAll(".label-group").remove();  // Remove the entire label group including text and rectangle
  g.selectAll(".label-circle").remove();
}

/**
 * Draw the scatter points
 * @param topics
 * @param g
 * @param xScale
 * @param yScale
 * @param setSelectedTopic
 * @param setMapSidebarCollapsed
 * @returns
 */

let activeLabel = null;

const NODE_COLOR = 'rgb(19, 37, 62, .2)'
const NODE_COLOR_BASE = 'rgb(19, 37, 62)'

let topicColorMap = null

export function createScatterPoints(
  docs: Document[] | undefined,
  topics: Topic[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  setSelectedTopic: React.Dispatch<React.SetStateAction<Topic | undefined>>,
  setSelectedDocument: React.Dispatch<React.SetStateAction<Document | undefined>>,
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>
) {
  if (!docs) return;


  const sampledDocs = docs.sort(() => 0.5 - Math.random()).slice(0, 10000);
  topicColorMap = topics.reduce((acc, topic) => {
    // Assign a unique color with opacity 20% for each topic_id, making them slightly more vibrant
    acc[topic.topic_id] = `hsla(${Math.floor(Math.random() * 360)}, 40%, 40%, 0.5)`; // Generate a random color with opacity 20%, making it slightly more vibrant
    return acc;
  }, {});
  const sizes = normalizeSizes(sampledDocs);

  // Create the visual points using streams
  const documentCentroids = g.selectAll("rect.document-centroid")
    .data(sampledDocs)
    .enter()
    .append("rect")
    .attr("class", "document-centroid");

  documentCentroids
    .attr("x", d => xScale(d.x)) // Adjust for half of the width
    .attr("y", d => yScale(d.y)) // Adjust for half of the height
    .style('fill', d => d.topic_id && topicColorMap[d.topic_id] ? topicColorMap[d.topic_id] : NODE_COLOR)

  // Create a quadtree for the document points
  const quadtree = d3.quadtree<Document>()
    .x(d => xScale(d.x))
    .y(d => yScale(d.y))
    .addAll(sampledDocs);

  // Update the mouse event handlers
  svg.on("mousemove", (event) => {
    const [mouseX, mouseY] = d3.pointer(event);
    const transform = d3.zoomTransform(svg.node() as SVGSVGElement);

    // Apply the inverse of the current transform to the mouse coordinates
    const transformedMouseX = transform.invertX(mouseX);
    const transformedMouseY = transform.invertY(mouseY);

    const nearest = quadtree.find(transformedMouseX, transformedMouseY, 7);

    if (nearest) {
      // Check if there's an existing active label for a different document
      if (activeLabel && activeLabel.doc_id !== nearest.doc_id) {
        // Destroy the previous label
        destroyLabel(g, activeLabel.doc_id);
        activeLabel = null;
      }
      // Only create the label if it does not already exist
      if (!g.select(`.label-for-${nearest.doc_id}`).node()) {
        activeLabel = nearest;  // Update the active label to the current document
        createLabel(nearest, topicColorMap, svg, g, xScale, yScale, zoom);
      }

    } else {
      // If no nearest document is found, destroy the current active label
      if (activeLabel) {
        destroyLabel(g, activeLabel.doc_id);
        activeLabel = null;
      }
    }
  });

  svg.on("mouseleave", () => {
    if (activeLabel) {
      destroyLabel(g, activeLabel.doc_id); // Remove the active label
      activeLabel = null; // Clear active label
    }
    // Reset all points to their original style
    g.selectAll("rect.document-centroid")
      .style("fill", d => d.topic_id && topicColorMap[d.topic_id] ? topicColorMap[d.topic_id] : NODE_COLOR)
      .style("stroke", 'none');
  });
}

export function destroyScatterPoints(g: d3.Selection<SVGGElement, unknown, null, undefined>) {
  g.selectAll("circle.document-centroid").remove();
  g.selectAll("circle.hit-area").remove(); // Also remove the larger invisible circles
}


let activeCentroidLabel = null;


export function highlightContour(
  topicId: string,
) {
  const currentlyClickedCountour = paths.find((path) => path.attr("id") === topicId);
  paths.forEach((path) => {
    path.style("stroke", "none") // Set all areas to white with 80% transparency
      .style("fill", "none")
      .style("opacity", 0.0);
  });
  if (currentlyClickedCountour) {
    currentlyClickedCountour
      .style("z-index", -1000)
      .style("fill", "#777991")
      .style("stroke", topicColorMap[topicId])
      .style("stroke-width", 3) // Remove fill for the selected contour
      .transition()
      .duration(800)
      .style("opacity", 0.2);
    // Zoom in to the location of the contour

  }
}

let paths = null;

export function createClusterCentroids(
  topics: Topic[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  setSelectedTopic: React.Dispatch<React.SetStateAction<Topic | undefined>>,
  setSelectedDocument: React.Dispatch<React.SetStateAction<Document | undefined>>,
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
) {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  let currentlyClickedTopic: d3.Selection<any, unknown, null, undefined> | null;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  let currentlyClickedCountour: d3.Selection<any, unknown, null, undefined> | null;
  let filteredTopics = topics.filter((d) => d.name && d.name?.toLowerCase() !== "no-topic");

  if (filteredTopics.every(d => d.name?.includes("|"))) {
    filteredTopics = filteredTopics.map((d) => ({ ...d, name: d.name?.replace(/\|/g, '-').toLowerCase().replace(/\s+/g, '') }));
  }

  let centroids = filteredTopics.filter((d) => d.name && d.x_centroid && d.y_centroid);

  // Get the current zoom level
  const currentZoomLevel = d3.zoomTransform(g.node() as Element).k;

  paths = createConvexHullContours(filteredTopics, g, xScale, yScale);
  // Add text labels for topic names

  const hitAreaOffset = 20; // Adjust this value to make the hit area larger

  const textLabels = g.selectAll("text.topic-label")
    .data(centroids)
    .enter()
    .append("text")
    .attr("class", "topic-label")
    .raise()
    .attr("id", (d) => d.topic_id)
    .attr("x", (d) => xScale(d.x_centroid))
    .attr("y", (d) => yScale(d.y_centroid))
    .text((d) => d.title ? d.title : `${d.name.slice(0, 35)}${d.name.length > 35 ? "..." : ""}`) // Use 'title' if it exists, otherwise 'name'
    .style("text-anchor", "middle")
    .style('font-family', '"Inter", sans-serif')
    .style('font-weight', '500')
    .style('text-shadow',
      `-1px -1px 0 white,  
       1px -1px 0 white,  
      -1px  1px 0 white,  
       1px  1px 0 white`)
    .style('cursor', 'pointer')
    .on("click", (event, d: Topic) => {
      setSelectedTopic(d);
      setSelectedDocument(undefined);

      highlightContour(d.topic_id);
      // setCollapsed(false);
    });
}

import { Footer } from "../../components/Footer";

export const CookiePolicy = () => {
    return (
        <div>
            <div>
                <div style={{padding: '2rem 4rem', display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                    <div>
                        <h3>What Are Cookies?</h3>
                        <p>
                            Cookies are small text files stored on your device (computer, tablet, or smartphone) when you visit a website. They help websites function
                            properly, improve user experience, and provide insights into how the site is being used.
                        </p>
                    </div>

                    <div>
                        <h3 style={{marginBottom: '1rem'}}>Types of Cookies We Use</h3>

                        {/* Essential Cookies */}
                        <div>
                            <h3>1. Essential Cookies</h3>
                            <p>
                                These cookies are necessary for our website to function and cannot be disabled. They are used for core features, such as security,
                                session management, and accessibility.
                            </p>
                            <ul>
                                <li><strong>Purpose:</strong> To ensure the website works as intended.</li>
                                <li><strong>Duration:</strong> Typically session-based and expire when you close your browser.</li>
                            </ul>
                        </div>

                        {/* Analytics Cookies */}
                        <div>
                            <h3>2. Analytics Cookies (Optional)</h3>
                            <p>
                                These cookies help us understand how visitors interact with our website by collecting anonymized or aggregated data. This information
                                helps us improve our services and user experience.
                            </p>
                            <ul>
                                <li><strong>Purpose:</strong> To measure basic usage information, such as the number of visitors and most viewed pages.</li>
                                <li>
                                    <strong>Examples:</strong> Tracking page views, monitoring time spent on the site.
                                </li>
                                <li><strong>Duration:</strong> May persist for a set period (e.g., 30 days).</li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <h3>How We Use Cookies</h3>
                        <p>We use cookies for the following purposes:</p>
                        <ul>
                            <li><strong>Essential Website Features:</strong> To ensure the website operates as expected.</li>
                            <li><strong>Improving User Experience:</strong> Analytics cookies help us understand how you use our site and make improvements.</li>
                        </ul>
                        <p>We do not use cookies for advertising or marketing purposes.</p>
                    </div>

                    <div>
                        <h3>Your Choices</h3>
                        <p>
                            You have full control over which cookies are stored on your device. When you visit our website, you will see a cookie consent banner that
                            allows you to:
                        </p>
                        <ul>
                            <li>Accept all cookies (essential and analytics).</li>
                            <li>Reject non-essential cookies (analytics).</li>
                        </ul>
                        <p>
                            You can change your preferences on the first entry of our website. You can also
                            manage cookies through your browser settings. However, blocking essential cookies may impact the functionality of our website.
                        </p>
                    </div>

                    <div>
                        <h3>Third-Party Services</h3>
                        <p>
                            We use a third-party analytics provider to help us measure and analyze website usage. These tools may place cookies on your device to collect anonymized data. For example:
                        </p>
                        <ul>
                            <li><strong>Tool Name:</strong> Heap</li>
                            <li><strong>Data Collected:</strong> Aggregated usage statistics (e.g., page views, session duration).</li>
                            <li>
                                <strong>Privacy Policy:</strong> <a href="https://heapanalytics.com/privacy">Heap Privacy Policy</a>
                            </li>
                        </ul>
                        <p>We ensure that any third-party tools comply with GDPR and anonymize your data where possible.</p>
                    </div>

                    <div>
                        <h3>How Long Are Cookies Stored?</h3>
                        <p>The duration of cookies depends on their type:</p>
                        <ul>
                            <li><strong>Session Cookies:</strong> These expire when you close your browser.</li>
                            <li>
                                <strong>Persistent Cookies:</strong> These remain on your device for a specified time or until you delete them. For analytics cookies,
                                this period is typically 30 days.
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h3>Updates to This Policy</h3>
                        <p>
                            We may update this Cookie Policy from time to time to reflect changes in technology, legal requirements, or how we use cookies. Any
                            updates will be posted on this page, and we encourage you to review it periodically.
                        </p>
                    </div>

                    <div>
                        <h3>Contact Us</h3>
                        <p>If you have any questions about this Cookie Policy or how we use cookies, please contact us:</p>
                        <ul>
                            <li><strong>Email:</strong> contact@bunka.ai</li>
                            <li><strong>Address:</strong> 45 rue d’Ulm, 75005 Paris, France</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
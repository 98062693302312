import { Footer } from "../../components/Footer";

export const LegalNotice = () => {
    return (
        <div>
            <div style={{ padding: '2rem 4rem', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                {/* Legal Notice Section */}
                <div>
                    <h2>Legal Notice</h2>

                    {/* Website Publisher */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>Website Publisher</h3>
                        <p>Bunka</p>
                        <p>45 rue d’Ulm, 75005 Paris, France</p>
                        <p>Email: <a href="mailto:contact@bunka.ai">contact@bunka.ai</a></p>
                    </div>

                    {/* Publication Director */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>Publication Director</h3>
                        <p>Charles de Dampierre</p>
                        <p>CEO & Cofounder</p>
                    </div>

                    {/* Hosting Provider */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>Hosting Provider</h3>
                        <p>Scaleway</p>
                        <p>Registered office: 8 rue de la Ville l’Evêque, 75008 Paris, France</p>
                        <p>
                            24-hour assistance:{" "}
                            <a href="https://console.scaleway.com/support/tickets">
                                https://console.scaleway.com/support/tickets
                            </a>
                        </p>
                    </div>
                </div>

                {/* Privacy Policy Section */}
                <div>
                    <h2>Privacy Policy</h2>

                    {/* 1. Collection of Personal Data */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>1. Collection of Personal Data</h3>
                        <p>We collect the following personal data:</p>
                        <ul>
                            <li>Connection data (IP address, logs)</li>
                            <li>Event specific data, page views, clicks</li>
                        </ul>
                    </div>

                    {/* 2. Use of Data */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>2. Use of Data</h3>
                        <p>Your personal data is collected and processed for the following purposes:</p>
                        <ul>
                            <li>Improving our services</li>
                        </ul>
                    </div>

                    {/* 3. Legal Basis for Processing */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>3. Legal Basis for Processing</h3>
                        <p>We process your data based on:</p>
                        <ul>
                            <li>Your consent</li>
                            <li>Contract execution</li>
                            <li>Our legitimate interests</li>
                            <li>Compliance with legal obligations</li>
                        </ul>
                    </div>

                    {/* 4. Data Retention Period */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>4. Data Retention Period</h3>
                        <p>Your personal data is kept for a limited time:</p>
                        <ul>
                            <li>Order data: 10 years (legal requirement)</li>
                            <li>Connection data: 1 year</li>
                        </ul>
                    </div>

                    {/* 5. Your Rights */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>5. Your Rights</h3>
                        <p>Under GDPR, you have the following rights:</p>
                        <ul>
                            <li>Right of access</li>
                            <li>Right to rectification</li>
                            <li>Right to erasure</li>
                            <li>Right to restrict processing</li>
                            <li>Right to data portability</li>
                            <li>Right to object</li>
                        </ul>
                        <p>
                            To exercise these rights, contact us at:{" "}
                            <a href="mailto:contact@bunka.ai">contact@bunka.ai</a>
                        </p>
                    </div>
                </div>

                {/* Terms of Use Section */}
                <div>
                    <h2>Terms of Use</h2>

                    {/* 1. Purpose */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>1. Purpose</h3>
                        <p>
                            These Terms of Use govern the use of{" "}
                            <a href="http://bunka.ai">bunka.ai</a> and{" "}
                            <a href="https://app.bunka.ai/">app.bunka.ai</a> by users.
                        </p>
                    </div>

                    {/* 2. Website Access */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>2. Website Access</h3>
                        <p>Access to the site is free. Users are responsible for their equipment and internet connection.</p>
                    </div>

                    {/* 3. Intellectual Property */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>3. Intellectual Property</h3>
                        <p>All content is protected by copyright. Any reproduction is prohibited without authorization.</p>
                    </div>

                    {/* 4. Liability */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>4. Liability</h3>
                        <p>While we strive to ensure the accuracy of information, we cannot guarantee it. We decline any liability for indirect damages.</p>
                    </div>

                    {/* 5. Modification of Terms */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>5. Modification of Terms</h3>
                        <p>We reserve the right to modify these terms at any time. Users will be informed of any modifications.</p>
                    </div>

                    {/* Data Protection Officer */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>Data Protection Officer</h3>
                        <p>For any questions regarding your personal data:</p>
                        <p>Data Protection Officer</p>
                        <p>Bunka</p>
                        <p>Email: <a href="mailto:contact@bunka.ai">contact@bunka.ai</a></p>
                    </div>

                    {/* Supervisory Authority */}
                    <div style={{marginBottom: '1rem'}}>
                        <h3>Supervisory Authority</h3>
                        <p>You have the right to lodge a complaint with the French Data Protection Authority (CNIL):</p>
                        <p>Commission Nationale de l'Informatique et des Libertés</p>
                        <p>3 Place de Fontenoy</p>
                        <p>TSA 80715</p>
                        <p>75334 PARIS CEDEX 07</p>
                        <p>
                            <a href="http://www.cnil.fr/">www.cnil.fr</a>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
